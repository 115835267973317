export default defineNuxtRouteMiddleware(async (to) => {
  const nuxt = useNuxtApp();
  const { user } = await useAuth();

  if (!user.value) {
    const i18nRedirected = useCookie("aprd.i18n_redirected");
    let locale: string | undefined;

    if (!i18nRedirected.value) {
      const normalizedLocales = getNormalizedLocales(nuxt.$i18n.locales.value);

      if (import.meta.client) {
        if (navigator.languages) {
          locale = findBrowserLocale(
            normalizedLocales,
            navigator.languages as string[]
          );
        }
      } else if (import.meta.server) {
        const headers = useRequestHeaders(["accept-language"]);
        const accept = headers["accept-language"];

        if (accept) {
          const acceptLocales = accept
            .split(",")
            .map((tag) => tag.split(";")[0]);

          locale = findBrowserLocale(normalizedLocales, acceptLocales);
        }
      }

      if (locale) {
        i18nRedirected.value = locale;
      }
    } else {
      locale = i18nRedirected.value;
    }

    if (locale && locale !== nuxt.$i18n.locale) {
      await nuxt.$i18n.setLocale(locale);
    }
  } else if (nuxt.$i18n.locale != user.value.locale) {
    await nuxt.$i18n.setLocale(user.value.locale);
  }
});

export function getNormalizedLocales(
  locales: string[] | { code: string; language?: string }[]
): { code: string; language?: string }[] {
  return locales.map((x) => (typeof x === "string" ? { code: x } : x));
}

/**
 * The browser locale info
 *
 * @remarks
 * This type is used by {@link FindBrowserLocaleOptions#sorter | sorter} in {@link findBrowserLocale} function
 */
interface BrowserLocale {
  /**
   * The locale code, such as BCP 47 (e.g `en-US`), or `ja`
   */
  code: string;
  /**
   * The score number
   *
   * @remarks
   * The score number that is used by `sorter` of {@link FindBrowserLocaleOptions}
   */
  score: number;
}

type TargetLocale = { code: string; language: string };

function matchBrowserLocale(
  locales: TargetLocale[],
  browserLocales: string[]
): BrowserLocale[] {
  const matchedLocales = [] as BrowserLocale[];

  // first pass: match exact locale.
  for (const [index, browserCode] of browserLocales.entries()) {
    const matchedLocale = locales.find(
      (l) => l.language.toLowerCase() === browserCode.toLowerCase()
    );
    if (matchedLocale) {
      matchedLocales.push({
        code: matchedLocale.code,
        score: 1 - index / browserLocales.length,
      });
      break;
    }
  }

  // second pass: match only locale code part of the browser locale (not including country).
  for (const [index, browserCode] of browserLocales.entries()) {
    const languageCode = browserCode.split("-")[0].toLowerCase();
    const matchedLocale = locales.find(
      (l) => l.language.split("-")[0].toLowerCase() === languageCode
    );
    if (matchedLocale) {
      // deduct a thousandth for being non-exact match.
      matchedLocales.push({
        code: matchedLocale.code,
        score: 0.999 - index / browserLocales.length,
      });
      break;
    }
  }

  return matchedLocales;
}

function compareBrowserLocale(a: BrowserLocale, b: BrowserLocale): number {
  if (a.score === b.score) {
    // if scores are equal then pick more specific (longer) code.
    return b.code.length - a.code.length;
  }
  return b.score - a.score;
}

/**
 * Find the browser locale
 *
 * @param locales - The target {@link LocaleObject} list
 * @param browserLocales - The locale code list that is used in browser
 * @param options - The options for {@link findBrowserLocale} function
 *
 * @returns The matched the locale code
 */
export function findBrowserLocale(
  locales: { code: string; language?: string }[],
  browserLocales: string[]
): string {
  const normalizedLocales = [];
  for (const l of locales) {
    const { code } = l;
    const language = l.language || code;
    normalizedLocales.push({ code, language });
  }

  // finding!
  const matchedLocales = matchBrowserLocale(normalizedLocales, browserLocales);

  if (matchedLocales.length === 0) {
    return "";
  }

  if (matchedLocales.length > 1) {
    // sort!
    matchedLocales.sort(compareBrowserLocale);
  }

  return matchedLocales[0].code;
}
