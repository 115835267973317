import type { AsyncDataOptions } from "#app";

type ProtectedDataOptions<ResT, DataT> = Omit<
  AsyncDataOptions<ResT, DataT>,
  "getCachedData"
> & {
  cache?: {
    /** Time in milliseconds */
    ttl?: number;
  };
};

export default async function useProtectedData<
  DataT,
  DataE = Error,
  ResT = DataT
>(
  key: string,
  path: string,
  { cache, ...options }: ProtectedDataOptions<ResT, DataT> = {}
) {
  const {
    public: { apiUrl },
  } = useRuntimeConfig();

  const token = useToken();

  const { data, error, status, refresh } = await useAsyncData<
    ResT,
    DataE,
    DataT
  >(
    key,
    () =>
      $fetch<ResT>(`${apiUrl}${path}`, {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }),
    {
      ...options,
      transform(data) {
        const _data = options.transform ? options.transform(data) : data;

        if (_data) {
          // @ts-ignore
          _data._$fetchedAt = Date.now();
        }

        return _data as DataT;
      },
      getCachedData(key, nuxtApp) {
        const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key];

        if (!data) {
          return;
        }

        const fetchedAt = new Date(data._$fetchedAt);
        const timeElapsed = Date.now() - fetchedAt.getTime();
        const isInvalid = cache?.ttl != null && timeElapsed >= cache.ttl;

        if (isInvalid) {
          return;
        }

        return data;
      },
      watch: (options.watch || []).concat(token),
    }
  );
  const loading = computed(
    () => status.value === "idle" || status.value === "pending"
  );

  return {
    data,
    error,
    loading,
    refresh,
  };
}
